import { setItem, getItem, checkItem } from "../../storageHelper/LocalStorage";
import {
  ADD_ITEM,
  DEL_ITEM,
  UPDATE_ITEM,
  ADD_MENU,
  DEL_MENU,
  EMPTY_CART,
} from "../actions/types";
import { ADDED_TO_CART, REMOVED_FROM_CART, UPDATED_PRODUCT } from "../../events";

const isBrowser = typeof window !== "undefined"; //Check if window is avaialble

const storeCart = (key, value) => {
  //Store cartProducts in localstorage
  setItem(key, JSON.stringify(value));
};

const getState = () => {
  const state = {
    //Individual items
    CartProducts: [],
    //Custom menu items
    MenuProducts: {},
    //Total cost of cart items
    CartTotal: 0,
    //Total cost of custom menu
    MenuTotal: 0,
  };
  //Check if CartProducts are in localstorage
  if (checkItem("CartProducts")) {
    //If products are found
    const loadedCartProducts = getItem("CartProducts", true);
    state.CartProducts =
      loadedCartProducts.length > 0 ? loadedCartProducts : []; //If true set the CartProducts
    isBrowser &&
      (state.CartTotal = loadedCartProducts.reduce((total, item) => {
        return (total += item.price);
      }, 0));
  }
  //Check if MenuProducts are in localstorage
  if (checkItem("MenuProducts")) {
    const loadedMenuProducts = getItem("MenuProducts", true);
    const hasData = Object.keys(loadedMenuProducts).length > 0;
    state.MenuProducts = hasData ? loadedMenuProducts : {}; //If true return the MenuProducts
    state.MenuTotal = hasData
      ? loadedMenuProducts.guests * loadedMenuProducts.total
      : 0;
  }
  return state;
};

const initialState = getState();

const CartReducer = (state = initialState, action) => {
  let newTotal = 0;
  switch (action.type) {
    case ADD_ITEM:
      window.analytics.logEvent(ADDED_TO_CART, {
        item_id: `${action.payload.id}`,
        item_name: action.payload.name,
        value: action.payload.price,
        type: "product",
      });
      let newProductList = [...state.CartProducts, action.payload];
      storeCart("CartProducts", newProductList);
      return {
        ...state,
        CartProducts: newProductList,
        CartTotal: state.CartTotal + action.payload.price,
      };
    case DEL_ITEM:
      newTotal = 0;
      let delProductList = state.CartProducts.filter((item) => {
        if (item.id === action.payload) {
          newTotal = state.CartTotal - item.price;
          //Send that product has been deleted to google analytics
          window.analytics.logEvent(REMOVED_FROM_CART, {
            item_id: `${item.id}`,
            item_name: item.name,
            value: item.price,
            type: "product",
          });
        }
        if (item.id !== action.payload) {
          return true;
        }
      });
      storeCart("CartProducts", delProductList);
      return {
        ...state,
        CartProducts: delProductList,
        CartTotal: newTotal,
      };
    case UPDATE_ITEM:
      window.analytics.logEvent(UPDATED_PRODUCT, {
        item_id: `${action.payload.id}`,
        item_name: action.payload.name,
        value: action.payload.newPrice,
        type: "product",
      });
      let updatedProductList = state.CartProducts.map((item) => {
        //If the item to be updated is found
        if (item.id === action.payload.id) {
          newTotal = state.CartTotal - item.price + action.payload.newPrice;
          return {
            ...item,
            quantity: action.payload.newQuantity, //Update its quantity
            price: action.payload.newPrice, //Update its price
          };
        } else {
          //If it is some other item then simply return the item unchanged
          return item;
        }
      });
      storeCart("CartProducts", updatedProductList);
      return {
        ...state,
        CartProducts: updatedProductList,
        CartTotal: newTotal,
      };
    case ADD_MENU:
      window.analytics.logEvent(ADDED_TO_CART, {
        item_id: `CSTM`,
        item_name: `Custom Menu`,
        value: state.MenuTotal,
        type: "menu",
      });
      storeCart("MenuProducts", action.payload);
      return {
        ...state,
        MenuProducts: action.payload,
        MenuTotal: action.payload.guests * action.payload.total,
      };

    case DEL_MENU:
      window.analytics.logEvent(REMOVED_FROM_CART, {
        item_id: `CSTM`,
        item_name: `Custom Menu`,
        value: state.MenuTotal,
        type: "menu",
      });
      storeCart("MenuProducts", {});
      return {
        ...state,
        MenuProducts: {},
        MenuTotal: 0,
      };
    case EMPTY_CART:
      return {
        CartProducts: [],
        MenuProducts: {},
        CartTotal: 0,
        MenuTotal: 0,
      };
    default:
      return state;
  }
};

export default CartReducer;
