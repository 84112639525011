import {
  PAYMENT_PROCESS_SUCCESS,
  SET_ORDER_DETAILS,
  PAYMENT_REDIRECT,
  SET_ORDER_DETAILS_START,
  RESET_TRANSACTION_INFO,
} from "../actions/types";
import {
  setItem,
  getItem,
  checkItem,
} from "../../storageHelper/SessionStorage";

const getState = () => {
  const state = {
    //loading is true from form submission to token generation
    loading: false,
    Processing: true,
    Redirect: false,
    //PaymentData to be sent to Cashfree
    PaymentData: {
      appId: "",
      orderId: "",
      customerName: "shoaib",
      customerPhone: "900850002",
      customerEmail: "shoaib@hmail.com",
      orderAmount: "",
      orderCurrency: "",
      returnUrl: "",
      signature: "",
      //Not needed for cashfree
      address1: "Line 1 ",
      address2: "Line 2",
      address3: "Line 3",
      landMark: "Here Only",
      date: "24/04/2021",
      time: "14:00",
    },
  };
  //Check if PaymentData is in localstorage
  if (checkItem("PaymentData")) {
    //If PaymentData is found
    const loadedPaymentData = getItem("PaymentData", true);
    state.PaymentData =
      Object.keys(loadedPaymentData).length > 0 ? loadedPaymentData : {}; //If true set the PaymentData
  }
  return state;
};

const initialState = getState();

const storeOrderInfo = (key, value) => {
  //Store cartProducts in SessionStorage
  setItem(key, JSON.stringify(value));
};

const PaymentReducer = (state = initialState, action) => {
  let newPaymentData = {};
  switch (action.type) {
    case SET_ORDER_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    //Afte the user fills up the form, take the form data and set PaymentData and MessageData
    case SET_ORDER_DETAILS:
      newPaymentData = {
        ...state.PaymentData,
        ...action.payload,
      };
      //Store the information in the SessionStorage
      storeOrderInfo("PaymentData", newPaymentData);
      return {
        ...state,
        PaymentData: newPaymentData,
      };
    case PAYMENT_PROCESS_SUCCESS:
      //Delete all storages
      localStorage.clear();
      sessionStorage.clear();
      return {
        loading: false,
        Processing: false,
        Redirect: false,
        //PaymentData to be sent to Cashfree
        PaymentData: {},
        MessageData: {},
      };
    case PAYMENT_REDIRECT:
      return {
        ...state,
        Redirect: true,
      };
    case RESET_TRANSACTION_INFO:
      //Store the information in the SessionStorage
      newPaymentData = {
        ...state.PaymentData,
        orderId: "",
        signature: "",
      };
      storeOrderInfo("PaymentData", newPaymentData);
      return {
        ...state,
        PaymentData: newPaymentData,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
