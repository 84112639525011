import { SET_OPERATING } from "../actions/types";
import { setCookie } from "../../storageHelper/Cookie";

const initialState = {
  set: false,
  operating: true,
  openTime: 0, //open time in minutes from 12am
  closeTime: 0, //open time in minutes from 12am
  preparationTime: 360, //6hours
  MaxGuestsServed: 100, //Number of guests that can be served when order is placed for same day delivery
  EarliestDeliveryBy: 0,
  LastestDeliveryBy: 0,
  EarliestDeliveryByFormatted: 0,
  LastestDeliveryByFormatted: 0,
};
const isBrowser = typeof window !== "undefined";
isBrowser &&
  (window.onload = () => {
    setCookie("sessionValid", "true", 6);
  });

// console.log("CHECK COOKIE",checkCookie('sessionValid'))

const getTime = (time) => {
  //Separate into Hour:Minutes:Seconds
  const separatedTime = time.split(":");
  const hours = parseInt(separatedTime[0]) * 60;
  const mins = parseInt(separatedTime[1]);
  return hours + mins;
};
const getFormat = (time) => {
  const separatedTime = time.split(":");
  const hours = separatedTime[0];
  const mins = separatedTime[1];
  return hours + ":" + mins;
};

const OperationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPERATING:
      return {
        ...state,
        set:true,
        openTime: getTime(action.payload.open),
        closeTime: getTime(action.payload.close),
        operating: action.payload.operating,
        preparationTime: action.payload.preparationTime,
        MaxGuestsServed: action.payload.MaxGuestsServed,
        EarliestDeliveryBy: getTime(action.payload.EarliestDeliveryBy),
        LastestDeliveryBy: getTime(action.payload.LastestDeliveryBy),
        EarliestDeliveryByFormatted: getFormat(
          action.payload.EarliestDeliveryBy
        ),
        LastestDeliveryByFormatted: getFormat(action.payload.LastestDeliveryBy),
      };

    default:
      return state;
  }
};

export default OperationReducer;
