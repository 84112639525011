import { combineReducers } from 'redux';
import PaymentReducer from './PaymentReducer'
import CartReducer from './CartReducer'
import ToggleReducer from './ToggleCartReducer'
import OperationReducer from './OperationReducer'

export default combineReducers({
    payment:PaymentReducer,
    cart:CartReducer,
    togglecart:ToggleReducer,
    operations:OperationReducer
}
);