const isBrowser = typeof window !== "undefined"; //Check if window is avaialble

export const setItem = (key, value) => {
  isBrowser && sessionStorage.setItem(key, value);
};
export const getItem = (key, parse = false) => {
  if (isBrowser && parse) return JSON.parse(sessionStorage.getItem(key));
  else return isBrowser && sessionStorage.getItem(key);
};
export const removeItem = (key) => {
  isBrowser && sessionStorage.removeItem(key);
};
export const checkItem = (name, checkParsedLength = false) => {
  const item = isBrowser && sessionStorage.getItem(name, checkParsedLength);
  // if it doesnt exist
  if (
    item == null ||
    JSON.parse(item) == null ||
    item.length == 0 ||
    (checkParsedLength && JSON.parse(item).length == 0)
  ) {
    return false;
  } else {
    return true;
  }
};
// null if not there
