/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css";
import "./src/styles/preloader.css";
import React from "react";
import store from "./src/Redux/store";
import { Provider } from "react-redux";

// Wraps every page in a component
export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};
