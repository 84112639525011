// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orderbuild-js": () => import("./../../../src/pages/orderbuild.js" /* webpackChunkName: "component---src-pages-orderbuild-js" */),
  "component---src-pages-paydone-js": () => import("./../../../src/pages/paydone.js" /* webpackChunkName: "component---src-pages-paydone-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/PaymentStatus.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-buy-product-js": () => import("./../../../src/templates/BuyProduct.js" /* webpackChunkName: "component---src-templates-buy-product-js" */),
  "component---src-templates-gallery-template-js": () => import("./../../../src/templates/GalleryTemplate.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */)
}

