//Payment
export const SET_ORDER_DETAILS_START="SET_ORDER_DETAILS_START"
export const SET_ORDER_DETAILS="SET_ORDER_DETAILS"
export const PAYMENT_REDIRECT="PAYMENT_REDIRECT"
export const PAYMENT_PROCESS_SUCCESS="PAYMENT_PROCESS_SUCCESS"
//Delete orderId and Signature
export const RESET_TRANSACTION_INFO="RESET_TRANSACTION_INFO"
export const RESTART_PAYMENT="RESTART_PAYMENT"

//CART
export const ADD_MENU="ADD_MENU"
export const DEL_MENU="DEL_MENU"
export const LOADING="LOADING"
export const ADD_ITEM="ADD_ITEM"
export const DEL_ITEM="DEL_ITEM"
export const UPDATE_ITEM="UPDATE_ITEM"


export const EMPTY_CART="EMPTY_CART"
export const RESET="RESET"
export const TOGGLE_CART="TOGGLE_CART"
//CART

//Operations
export const SET_OPERATING="SET_OPERATING"